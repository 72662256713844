$(document).ready(function () {
    $(".dropdown_ctrl").on("mouseover", function (evt) {
        if (window.screen.width <= 991) {
            return;
        }
        
        if ( $("#id-dropdown-icon").hasClass("fa-caret-down") ) {
            evt.preventDefault();
            evt.stopPropagation();
            dropdown = $(".c-dropdown");

            var is_open = dropdown.hasClass('c-dropdown--open');

            if (is_open) {
                dropdown.removeClass('c-dropdown--open');
                $("#id-dropdown-icon").removeClass("fa-caret-up");
                $("#id-dropdown-icon").addClass("fa-caret-down");
            } else {
                dropdown.addClass('c-dropdown--open');
                $("#id-dropdown-icon").addClass("fa-caret-up");
                $("#id-dropdown-icon").removeClass("fa-caret-down");

            }
        }
    });

    $(".c-dropdown__sub-dropdown-button").on("click", function(evt){
        evt.preventDefault();
        evt.stopPropagation();
        dropdown = $(".c-dropdown");
        var is_open = dropdown.hasClass('c-dropdown--open');
        if (is_open) {
            dropdown.removeClass('c-dropdown--open');
            $("#id-dropdown-icon").removeClass("fa-caret-up");
            $("#id-dropdown-icon").addClass("fa-caret-down");
        } else {
            dropdown.addClass('c-dropdown--open');
            $("#id-dropdown-icon").addClass("fa-caret-up");
            $("#id-dropdown-icon").removeClass("fa-caret-down");
        }
    });



    $(".c-dropdown__title").on("click", function(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        window.open("/what-we-do", "_self");

    });

    $(".dropdown_ctrl").on("mouseout", function (evt) {
        if (window.screen.width <= 991) {
            return;
        }
        dropdown = $(".c-dropdown");
        dropdown.removeClass('c-dropdown--open');
        $("#id-dropdown-icon").removeClass("fa-caret-up");
        $("#id-dropdown-icon").addClass("fa-caret-down");
    });

    
});