$(document).ready(function () {

    var carousel = $('.s-testimony__carousel');
    carousel.owlCarousel({
        items: 1,
        loop: true,

    });

    $(document).on('click', '.s-testimony__prev', function () {
        $('.owl-prev').trigger('click');
    });

    $(document).on('click', '.s-testimony__next', function () {
        $('.owl-next').trigger('click');
    });

});