$(document).ready(function () {

    // c-select

    $('.c-select').each(function () {

        var select = $(this);
        var selected = select.find('.c-select__selected');
        var input = select.find('.c-select__input');
        var option_s = select.find('.c-select__option');

        selected.text(input.find('option:selected').text());

        selected.click(function (evt) {
            evt.stopPropagation();
            var is_closed = select.hasClass('c-select--closed');
            if (is_closed) {
                select.removeClass('c-select--closed');
            } else {
                select.addClass('c-select--closed');
            }
        });

        option_s.click(function (evt) {
            evt.stopPropagation();
            var option = $(this);
            var id = option.attr('data-id');
            input.val(id);
            selected.text(input.find('option:selected').text());
            select.addClass('c-select--closed');
        });

    });

});