$(document).ready(function () {
    var body = $('body');
    var menu = $('.s-menu');
    var burger = $('.s-menu__burger');

    burger.click(function () {
        menu.toggleClass('s-menu--open');
        body.toggleClass('modal-open');
    })

});