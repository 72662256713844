$(document).ready(function () {

    // Clicking away function
    $(window).click(function () {

        // close "c-select"
        $('.c-select').addClass('c-select--closed');

        // close "c-dropdown"
        $('.c-dropdown').removeClass('c-dropdown--open');

    });

});