$(document).ready(function () {
    var riser = $('.c-riser');
    if ((riser).length > 0) {
        var has_scroll = riser.hasClass('c-riser--scroll');
        var scroll_to = $("#scroll-to");
        if (scroll_to.length > 0) {
            setTimeout(function () {
                $('html, body').animate({
                    scrollTop: scroll_to.offset().top
                }, 1000);
            }, 8000);
        }
    }
});